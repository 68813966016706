.bt_edit{
    width: 15px;
    height: 25px;
}
.bt_del {
    width: 15px;
    height: 25px;
    background-color: 'red';
}
.bt_info {
    width: 15px;
    height: 25px;
}

